const import_js_imported = [];
const DEV_URL = '/';
const PROD_URL = 'https://slicing.dg-apps.com/dg-template/';
const env = process.env.NODE_ENV;

$.extend(true, {
    import_js : function(script) {
        var found = false;

        for (var i = 0; i < import_js_imported.length; i++)
        if (import_js_imported[i] == script) {
            found = true;
            break;
        } if (found == false) {
            if (env == 'production') {
                $("body").append('<script type="text/javascript" src="'+ PROD_URL + script + '"></script>');
            } else {
                $("body").append('<script type="text/javascript" src="'+ DEV_URL + script + '"></script>');
            }
            import_js_imported.push(script);
        }
    },

    import_css : function(css) {
        var found = false;

        for (var i = 0; i < import_js_imported.length; i++)
        
        if (import_js_imported[i] == css) {
            found = true;
            break;
        } if (found == false) {
            $("head").append('<link rel="stylesheet" href="'+ BASE_URL + css + '">');
            import_js_imported.push(css);
        }
    }
});