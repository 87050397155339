const Header = function () {
    const elDocument = $(document),
          elBody = $('body');

    const onFixed = function () {
        let elFixed = $('[data-menu-fixed]'),
            elFixedResult = "fixed";

        elDocument.on('scroll', function() {
            if (elDocument.scrollTop() > 0) {
                elFixed.addClass(elFixedResult);
            } else {
                elFixed.removeClass(elFixedResult);
            }
        });
    };

    const onScroll = function () {
        let elScroll = $('[data-toggle="scroll"]'),
            elScrollResult = $('.has-scroll'),
            elThis = $(this),
            elOffset = $(elThis).data('offset') || 0;

        window.Helper = (typeof Helper !== "undefined") ? Helper : {};

        Helper.scroll_to = function(el, top){
            $ = jQuery;
            top = top || 0;
        
            elBody.animate({
                scrollTop: $(el).offset().top + top
            }, 500);
        }

        elScroll.on('click', function(e){ 
            e.preventDefault();

            elScrollResult.fadeIn(2000, function() {});
            Helper.scroll_to($($(elThis).data('target')), elOffset);
        });
    };

    const onHamburger = function () {
        let element = $('[data-toggle="hamburger"]');
        let targetElement = $('.hamburger');

        element.on('click', function(e) {
            $(this).toggleClass('show');
            targetElement.toggleClass('show');
        });
    };
    
    return{
        initActions: function () {
            onFixed();
            onScroll();
            onHamburger();
        },
        initCore: function () {
            Header.initActions()
        }
    }
}();

document.addEventListener('DOMContentLoaded', function() {
    Header.initCore();
});