//import Swiper from 'swiper/bundle';

var heroBanner = new Swiper(".hero-banner", {
    loop: true,
    autoplay: {
        delay: 4500,
        disableOnInteraction: false,
    },
    navigation: {
        nextEl: ".nav-next",
        prevEl: ".nav-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        //dynamicBullets: true,
    },
});


var proyekSlider = new Swiper(".proyek", {
    slidesPerView: 5,
    //slidesPerView: "auto",
    spaceBetween: 20,
    //centeredSlides: true,
    loop: true,
    navigation: {
        nextEl: ".proyek-nav-next",
        prevEl: ".proyek-nav-prev",
    },
    breakpoints: {
        375: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 15,
        },
    },
});


var produkSlider = new Swiper(".produk-aplikasi", {
    slidesPerView: 3,
    spaceBetween: 20,
    loop: false,
    navigation: {
        nextEl: ".prod-nav-next",
        prevEl: ".prod-nav-prev",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 5,
        },
        1024: {
            //slidesPerView: 3,
            spaceBetween: 10,
        },
        1360: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1439: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1900: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
    },
});

// IN PAGE PRODUK SLIDER
var prodSlides = new Swiper('.produkSlider', {
    loop: true,
    autoplay: true,
    //initialSlide: 2,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
    },
});


var prodTypeSlides = new Swiper('.productTypeSlider', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
		
