import Modal from 'modal-vanilla';
import { Fancybox } from "@fancyapps/ui";

if (document.querySelectorAll('[data-component="modal"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        document.querySelector('.modal-example').addEventListener('click', function () {  
            new Modal({
                el: document.getElementById('modal-example')
            }).show();

            console.log('success')
        })
    })
}

Fancybox.bind("[data-fancybox]", {
    // Your custom options
});
  